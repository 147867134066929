<template>
  <div class="p-4 bg-white text-sm">
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
